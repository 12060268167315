import { useNavigate, useParams } from "react-router-dom";
import PanelHeader from "../../components/panel-header/PanelHeader";
import PolicyCoverageList from "./PolicyCoverageList";
import { fetchPolicy } from "../../apis/PoliciesApi";
import { useQuery } from "react-query";
import { Button, Card, CardHeader, Label, Spinner, Tooltip } from "@fluentui/react-components";
import ErrorBar from "../../components/error-bar/ErrorBar";
import PersonCard from "../../components/person-card/PersonCard";
import { capitalize } from "../../utils/StringUtils";
import usePolicyDetailsStyles from "./PolicyDetailsStyles";
import { ArrowLeftRegular } from "@fluentui/react-icons";

const PolicyDetails: React.FunctionComponent = () => {
    const { policyId } = useParams();
    const styles = usePolicyDetailsStyles();
    const navigate = useNavigate();
    const { isLoading, data, isError } = useQuery({
        queryKey: ['policy', policyId],
        queryFn: () => fetchPolicy(policyId || ''),
        refetchOnWindowFocus: false
    });

    return (
        <div>
            <PanelHeader title={`Policy #${policyId}`} >
                <Tooltip content="Back to list" relationship="label">
                    <Button onClick={() => navigate('/policies')} aria-label="Back to list" icon={<ArrowLeftRegular />} />
                </Tooltip>
            </PanelHeader>
            {isLoading && <Spinner />}
            {isError && <ErrorBar message='Something went wrong during policy load. Please refresh page or contact administrator' />}

            {!isLoading && !isError &&
                <span>
                    <div className={styles.headerWrapper}>
                        <Card className={styles.card}>
                            <CardHeader
                                header={
                                    <Label weight="semibold">
                                        Policy details
                                    </Label>
                                }
                            />
                            <p className={styles.text}>
                                <Label>{capitalize(data!.policy_status)}</Label>
                                <Label>{data?.concept_name}</Label>
                                <Label>{new Date(data!.period.start_date).toLocaleDateString()} -  {new Date(data!.period.end_date!).toLocaleDateString()}</Label >
                            </p>
                        </Card>
                        {data?.holder != null &&
                            <Card className={styles.card}>
                                <CardHeader
                                    header={
                                        <Label weight="semibold">
                                            Holder details
                                        </Label>
                                    }
                                />
                                <p className={styles.text}>
                                    <Label>{data.holder.company_name === "" ? (data.holder.first_name + " " + data.holder.last_name) : data.holder.company_name}</Label>
                                    <Label>{data.holder.email_address}</Label>
                                    <Label>{data.holder.mobile_phone_number}</Label>
                                </p>
                            </Card>
                        }
                    </div>
                    <PolicyCoverageList policyId={policyId!} />
                </span>
            }
        </div >
    );
}

export default PolicyDetails;